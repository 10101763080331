<template>
    <div id="formBody" class="bg-light">
        <div id="wrapper">
            <nav class="navbar navbar-expand-lg navbar-light bg-light">
                <div class="container" style="display:block">
                    <a class="navbar-brand" href="#">
                        <img border="0" src="https://app.sygnali.pl/img/sygnali_logo_450.f15c393f.png">
                    </a>
                    <div class="tmenu">
                        <a class="link" href="https://pomoc.mwc.pl/sygnalista" target="_blank">
                            <i class="fal fa-question-circle"></i> Pomoc
                        </a>
                        <a class="link" @click="goToSygnalistInbox()" style="cursor:pointer">
                            <i class="fal fa-inbox-in"></i> Skrzynka odbiorcza
                        </a>
                        <a id="wcaglink" @click="wcag()" class="link" style="cursor:pointer"><i class="fal fa-wheelchair"></i> WCAG</a>
                    </div>
                    <div class="cb"></div>
                </div>
            </nav>
            <div id="sterowanie">
                <a class="kontrast" @click="kontrast()" style="cursor:pointer"><i class="fa fa-adjust" aria-hidden="true"></i> zmień kontrast</a>
                <a @click="font(0.1)" style="cursor:pointer"><i class="fa fa-plus" aria-hidden="true"></i> powiększ czcionkę</a>
                <a @click="font(-0.1)" style="cursor:pointer"><i class="fa fa-minus" aria-hidden="true"></i>pomniejsz czcionkę</a>
            </div>
            <div class="container">
                <main>
                    <div class="py-5 text-center">
                    </div>
                    <div class="row rfl">
                        <div class="col-8">
                            <div class="row">
                                <div class="col-8 col-md-8" style="float: left;">
                                    <h4 class="thin">Formularz zgłoszenia dla Sygnalisty</h4>
                                    <h3 class="mb-3"><strong>{{company}}</strong></h3>
                                </div>
                                <div class="col-2 col-md-4">
                                    <v-img v-if="logo.name != null && logo.name.length > 0" :src="logo.name" style="width: 130px; float: right;"></v-img>
                                </div>
                            </div>
                            <input id="pkid" name="pkid" type="hidden" value="0">
                            <div class="row g-3">
                                <div class="col-12">
                                    <div class="input-group has-validation">
                                        <span class="input-group-text">Kategoria zgłoszenia</span>
                                        <select id="kategoria" class="form-select" name="kategoria" required="" @change="getFormForCategory($event)">
                                            <option v-for="item in categories" :key="item.id" :value="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <form id="formularz" class="needs-validation">
                                <div class="mt-2 row g-3">
                                    <div v-for="item in form" :key="item.id">
                                        <div class="col-12" v-if="item.type == 2">
                                            <div class="input-group has-validation">
                                                <span class="input-group-text">{{item.label}}</span><span class="input-group-text" title="Zawartość tego pola zostanie zaszyfrowana">
                                                    <i class="fal fa-shield-check"></i>
                                                </span>
                                                <input :id="'input_'+item.id+'_field'" class="form-control encrypt" encrypted="1" :name="item.label+'&'+item.type+'#'+item.id" placeholder="" :required="item.required" type="text">
                                            </div>
                                        </div>
                                        <div class="col-12" v-if="item.type == 1">
                                            <label class="form-label" for="address">{{item.label}}</label>
                                            <div class="input-group has-validation">
                                                <span class="input-group-text" title="Zawartość tego pola zostanie zaszyfrowana">
                                                    <i class="fal fa-shield-check"></i>
                                                </span>
                                                <textarea :id="'zgloszenie_'+item.id+'_field'" class="encrypt form-control" encrypted="1" :name="item.label+'&'+item.type+'#'+item.id" :required="item.required" rows="10"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-12" v-if="item.type == 4">
                                            <div class="input-group has-validation">
                                                <span class="input-group-text">{{prepareLabelForSelect(item.label)}}</span>
                                                <span class="input-group-text" title="Zawartość tego pola zostanie zaszyfrowana">
                                                    <i class="fal fa-shield-check"></i>
                                                </span>
                                                <select class="encrypt form-select" encrypted="1" :required="item.required" :name="item.label+'&'+item.type+'#'+item.id">
                                                    <option value="" selected>Wybierz</option>
                                                    <option v-for="item in preapareOptions(item.label)" :key="item.id" :value="item">{{item}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12" v-if="item.type == 3">
                                            <div class="form-check">
                                                <input :id="'checkbox_'+item.id+'_field'" :name="item.label+'&'+item.type+'#'+item.id" class="form-check-input" :required="item.required" type="checkbox">
                                                <label class="form-check-label" :for="'checkbox_'+item.id+'_field'">{{item.label}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-label" v-if="!isAnonymous" style="margin-bottom: 0px;">Dane sygnalisty</div>
                                    <div class="col-12" v-if="!isAnonymous" style="margin-top: 0px;">
                                        <div class="input-group has-validation">
                                            <span class="input-group-text">Imię i nazwisko</span>
                                            <span class="input-group-text"><i class="fal fa-info-circle"></i></span>
                                            <input id="sygnalistName" class="form-control" v-model="sygnalistName" :required="!isAnonymous">
                                        </div>
                                    </div>
                                    <div class="col-12" v-if="!isAnonymous" style="margin-top: 0px;">
                                        <div class="input-group has-validation">
                                            <span class="input-group-text">Adres e-mail</span>
                                            <span class="input-group-text"><i class="fal fa-info-circle"></i></span>
                                            <input id="email" class="form-control" v-model="email" :required="!isAnonymous">
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="mt-2 row g-3">
                                <hr>
                                <div class="col-12" v-if="isFiles">
                                    <h4 class="justify-content-between align-items-center mb-3">
                                        <button class="btn btn-primary" @click="addFile()" style="float:right" role="button">+ dodaj załącznik</button>
                                        Załączniki
                                    </h4>
                                    <div v-if="files != null && files.length > 0">
                                        <ul class="zalaczniki" v-for="item in files" :key="item[0].name">
                                            <li>
                                                {{item[0].name}} <v-btn icon @click="deleteFile(item)">
                                                    <v-icon style="color:red">mdi-trash-can</v-icon>
                                                </v-btn>
                                            </li>
                                        </ul>
                                    </div>
                                    <div v-else>
                                        <ul class="zalaczniki">
                                            <li>Brak załączników...</li>
                                        </ul>
                                    </div>
                                    <label class="small mt-1">*PDF, DOC, DOCX, JPG, PNG</label>
                                </div>
                                <button class="mb-3 w-100 btn btn-primary btn-lg" id="saver" @click="fsubmit()" type="button">
                                    <i id="unlock" class="far fa-lock-open-alt"></i> Zaszyfruj i wyślij zgłoszenie
                                </button>
                            </div>

                        </div>
                        <div class="col-4">
                            <div class="row" v-if="attachments != null && attachments.length > 0">
                                <h4 class="d-flex justify-content-between align-items-center mb-3">
                                    <span class="text-primary">Do pobrania</span>
                                </h4>
                                <ul v-for="item in attachments" :key="item.id">
                                    <li class="lead">
                                        <span @click="getAttachment(item)" style="cursor: pointer">{{item.name}}</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="row kanaly" v-if="phoneEnabled || mailEnabled">
                                <h4 class="d-flex justify-content-between align-items-center mb-3">
                                    <span class="text-primary">Dodatkowe kanały zgłoszenia</span>
                                </h4>
                                <h5 v-if="phoneEnabled">Zgłoszenie telefoniczne</h5>
                                <p class="lead" v-if="phoneEnabled">
                                    W celu przekazania zgłoszenia za pomocą telefonu, zadzwoń pod numer <b>+48 616 313 431</b> i podaj następujący kod PIN: <b>{{pin}}</b>.
                                    System Sygnali automatycznie przetworzy Twoje nagranie na tekst i prześle je do właściwego odbiorcy. Nagranie telefoniczne jest anonimowe - nie przekazujemy Twojego numeru telefonu.
                                </p>
                                <h5 v-if="mailEnabled">Zgłoszenie mailowe</h5>
                                <p class="lead" v-if="mailEnabled">
                                    Aby przekazać Twoje zgłoszenie mailem, wyślij je na adres: <b>{{pin}}@my.sygnali.pl</b>. Jest to zgłoszenie anonimowe, Twój adres e-mail nie zostanie przekazany odbiorcy.
                                </p>
                                <hr />
                            </div>
                            <div class="row kanaly">
                                <h4 class="d-flex justify-content-between align-items-center mb-3">
                                    <span class="text-primary">Informacje</span>
                                </h4>
                                <p class="lead" v-if="isAnonymous">
                                    Twoje zgłoszenie zostanie przekazane bezpiecznie i anonimowo
                                    do odbiorcy. Odbiorca nie będzie w stanie ustalić nadawcy. Nie podawaj w
                                    zgłoszeniu wrażliwych danych osobowych, jeżeli nie jest to konieczne.
                                </p>
                                <p class="lead" v-else>
                                    Twoje zgłoszenie zostanie przekazane bezpiecznie
                                    do odbiorcy. Nie podawaj w
                                    zgłoszeniu wrażliwych danych osobowych, jeżeli nie jest to konieczne.
                                </p>
                                <p>
                                    Wysyłając zgłoszenie akceptujesz postanowienia
                                    <a href="https://sygnali.pl/regulamin" target="_blank">Regulaminu </a>oraz
                                    <a href="https://sygnali.pl/polityka-prywatnosci" target="_blank">
                                        Polityki
                                        Prywatności
                                    </a> systemu Sygnali.
                                </p>
                                <p>Przeczytaj też <a href="https://sygnali.pl/bezpieczenstwo-sygnalisty-2" target="_blank">Jak chronimy Sygnalistów</a>.</p>
                            </div>
                            <div class="row mt-3">
                                <hr>
                                <p class="mt-2">&copy; {{new Date().getFullYear()}} MWC Sp. z o.o.</p>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <SygnaliModal v-show="showSyganliModal" @close="closeModal" :sygnal="sygnal" />
        </div>
    </div>
</template>
<script src="../../../form/bootstrap.bundle.min.js"></script>
<script>
    import Service from '@/services/Service'
    import $ from 'jquery'
    import alertify from 'alertifyjs'
    import CryptoJS from 'crypto-js'
    import SygnaliModal from '@/components/Modals/SygnaliModal'
    export default {
        name: 'SygnaliForm',
        components: {
          SygnaliModal,
        },
        data() {
            return {
                textarea: '',
                title: '',
                files: [],
                company: '',
                showSyganliModal: false,
                sygnal: {},
                c: 0,
                f: 1.0,
                main: [],
                categories: [],
                form: {},
                email: '',
                sygnalistName: '',
                isAnonymous: true,
                isFiles: true,
                pk: '',
                pkid: 0,
                catid: 0,
                attachments: [],
                phoneEnabled: false,
                mailEnabled: false,
                pin: '',
                logo: {
                    id: 0,
                    name: ''
                }
            }
        },
        async mounted() {
            try {
                this.main = (await Service.getSygnaliForm(this.$route.params.companyName)).data,
                this.company = this.main.company,
                this.categories = this.main.categories,
                this.isAnonymous = this.categories[0].anonymous,
                this.isFiles = this.categories[0].files,
                this.catid = this.main.categories[0].id,
                this.attachments = this.main.categories[0].attachments,
                this.form = this.main.fields,
                this.phoneEnabled = this.main.phoneEnabled,
                this.mailEnabled = this.main.mailEnabled,
                this.pin = this.main.pin,
                this.logo = this.main.logo
            } catch (e) {
                alert(e.response.status + " - " + e.response.data.toString());
                 this.$router.push({ name: 'Error2' });
            }
        },
        created() {
            document.title = "Sygnali.pl - Formularz zgłoszeniowy";
        },
        methods: {
            preapareOptions(item) {
                var opt = item.split('|')[1];
                var o = opt.split(',');
                return o;
            },
            prepareLabelForSelect(item) {
                return item.split('|')[0];
            },
            async getFormForCategory(item) {
                try {
                   
                    var temp = {
                        companyName: this.$route.params.companyName,
                        id: parseInt(item.target.value)
                    };
                    var result = (await Service.getFieldsForCategory(temp)).data;
                    if (Object.keys(result).length > 0) {
                        this.form = result.fields;

                        var index = this.categories.findIndex(x => x.id == parseInt(item.target.value));
                        if (index != -1) {
                            this.categories[index] = result.category;
                            this.isAnonymous = result.category.anonymous;
                            this.isFiles = result.category.files;
                            this.catid = result.category.id;
                            this.attachments = result.category.attachments;
                        }
                    }
                } catch (e) {
                    alert(e.response.status + " - " + e.response.data.toString());
                    this.$router.push({ name: 'Error2' });
                }
            },
            wcag() {
                $("#sterowanie").slideToggle()
            },
            kontrast() {
                if (this.c == 0) {
                    $('#formBody').addClass("black").removeClass('bg-light');
                    this.c = 1;
                }
                else {
                    $('#formBody').removeClass("black").addClass('bg-light');
                    this.c = 0;
                }
            },
            font(x) {
                this.f = this.f + x;
                this.f = Math.round(this.f * 100) / 100
                document.getElementById("wrapper").style.fontSize = this.f + "em";
            },
            closeModal() {
                this.showSyganliModal = false;
                this.textarea = '';
                this.title = '';
                this.files = [];
                window.location.reload(true);
            },
            goToSygnalistInbox() {
                let routeData = this.$router.resolve({ name: 'InboxLogin' });
                window.open(routeData.href, '_blank');
            },
            goToHelpPage() {
                window.open('https://pomoc.mwc.pl/sygnalista', '_blank').focus();
            },
            fsubmit() {
                var thisVue = this;
                alertify.confirm('Potwierdź zgłoszenie', 'Czy na pewno chcesz wysłać zgłoszenie?', function () {
                    setTimeout(thisVue.wysylaj(), 100);
                }, function () { }).set('labels', { ok: 'Wyślij', cancel: 'Anuluj' });
            },
            wysylaj() {
                var thisVue = this;
                const form = document.getElementById("formularz");
                if (!form.checkValidity()) {
                    alertify.error('Wystąpiły błędy w formularzu. Popraw dane i spróbuj ponownie.');
                } else { thisVue.encrypt(); return false }
                form.classList.add('was-validated')
            },
            encrypt() {
                var thisVue = this;
                alertify.notify('Trwa pobieranie klucza publicznego...');
                setTimeout(thisVue.get_public_key(), 1000);
            },
            get_public_key() {
                var thisVue = this;
                $.getJSON("https://key.sygnali.pl/get_public_key", function (json) {
                    alertify.dismissAll();
                    thisVue.pk = json.key;
                    var pkid = json.id;
                    thisVue.pkid = json.id;
                    $('#pkid').val(pkid);
                    alertify.success("Pobrano pomyślnie klucz publiczny.");
                    setTimeout(() => { alertify.notify('Trwa szyfrowanie danych...'); thisVue.szyfruj() }, 800);
                });
            },
            szyfruj() {
                var thisVue = this;
                var criteria = $('#formularz').find('input.encrypt,textarea.encrypt').filter(function () {
                    var v = this.value;
                    v = v.replace(/<\/?[^>]+(>|$)/g, "");
                    var c = CryptoJS.AES.encrypt(v, thisVue.pk).toString();
                    this.value = c;
                })

                $('select.encrypt').each(function () {
                    var v = this.value;
                    var n = this.name;
                    v = v.replace(/<\/?[^>]+(>|$)/g, ""); //usuwanie htmla z treści formularzy
                    n = n.replace(/<\/?[^>]+(>|$)/g, "");
                    var c = CryptoJS.AES.encrypt(v, thisVue.pk).toString();
                    var z = "<input class='encrypt form-control' name='" + n + "' type='text' value='" + c + "'>";
                    $(this).replaceWith(z);
                })

                $('.encrypt').each(function () {
                    var n = this.name;
                    $(this).attr("name", n + '_encrypted');
                })

                setTimeout(thisVue.wyslij_dane(), 1100);
            },
            wyslij_dane() {
                var thisVue = this;
                alertify.dismissAll();
                alertify.success('Pomyślnie zaszyfrowano dane.');
                $('#unlock').replaceWith('<i class="far fa-lock-alt"></i>');
                setTimeout(alertify.notify('Trwa wysyłanie zgłoszenia...'), 100);

                var formularz = $('#formularz').serializeArray()
                var formData = JSON.stringify(formularz);
                var w = formData;
                var b64 = this.b64encode(w);
                setTimeout(function () { thisVue.sendMessage(b64) }, 700);
            },
            b64encode(x) {
                var result = btoa(unescape(encodeURIComponent(x)));
                return result;
            },
            b64decode(x) {
                var result = atob(x);
                return result;
            },
            async sendMessage(base64) {
                try {
                    let formData = new FormData();
                    for (var i = 0; i < this.files.length; i++) {
                        let file = this.files[i][0];
                        formData.append('files[' + i + ']', file);
                    }

                    formData.append("msg", base64);
                    formData.append("pkid", parseInt(this.pkid));
                    formData.append("catId", this.catid);
                    formData.append("name", this.sygnalistName);
                    formData.append("email", this.email);

                    var result = (await Service.saveFileForSignal(this.$route.params.companyName, formData)).data;
                    if (Object.keys(result).length > 0) {
                        this.sygnal = result;
                        this.showSyganliModal = true;
                        this.files = [];
                    }
                      
                } catch (e) {
                    alert(e.response.status + " - " + e.response.data.toString());
                    this.$router.push({ name: 'Error2' });
                }
            },
            addFile() {
                let input = document.createElement('input');
                input.type = 'file';
                input.accept = '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.png,.jpg,.jpeg';
                input.multiple = false;
                input.click();
                input.addEventListener('change', (event) => {
                    if (event.target.files[0].size <= 30000000) {
                        if (this.checkMimeType(event.target.files[0].type)) {
                            if (this.files.length > 0) {
                                for (var i = 0; i < this.files.length; i++) {
                                    if (this.files[i][0].name == event.target.files[0].name)
                                        return;
                                }

                                var tempFileSize = 0;
                                for (var j = 0; j < this.files.length; j++) {
                                    tempFileSize += this.files[j][0].size;
                                }
                                tempFileSize += event.target.files[0].size;
                                if (tempFileSize >= 30000000) {
                                    alert("Zbyt duży rozmiar pliku! Suma załączników nie może przekraczać 30MB");
                                    return;
                                }

                                this.files.push(event.target.files);
                            }
                            else {
                                this.files.push(event.target.files);
                            }
                        }
                        else {
                            alert("Nieobsługiwany typ pliku. Pliki obsługiwane to: DOC,DOCX,PDF,JPG,JPEG,PNG")
                        }
                    } else {
                        alert("Zbyt duży rozmiar pliku! Suma załączników nie może przekraczać 30MB");
                    }
                });
            },
            checkMimeType(type) {
                if (type != "application/pdf" && type != "image/jpeg" && type != "image/jpg" &&
                    type != "image/png" && type != "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
                    type != "text/plain") {
                    return false;
                } else {
                    return true;
                }
            },
            deleteFile(item) {
                if (this.files.length > 0) {
                    for (var i = 0; i < this.files.length; i++) {
                        if (this.files[i][0].name == item[0].name) {
                            var index = this.files.indexOf(this.files[i])
                            this.files.splice(index, 1);
                        }
                    }
                }
            },
           async getAttachment(item) {
                try {
                    var temp = {
                        companyName: this.$route.params.companyName,
                        fileId: item.id,
                        fileName: item.name,
                        catId: this.catid
                    };

                    var result = (await Service.downloadAttachment(temp));

                    if (result.status == 200) {
                        const contentType = result.headers['content-type'];
                        var a = document.createElement('a');
                        var blob = new Blob([result.data], { 'type': contentType });
                        a.href = window.URL.createObjectURL(blob);
                        a.download = item.name;
                        a.click();
                    }
                } catch (e) {
                    alert(e.response.status + " - " + e.response.data.toString());
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            }
        },
    }

</script>

<style scoped>
    @import '../../../form/custom.css';
   /* @import '../../../form/form-validation.css';*/
    @import '../../../form/fontawesome/css/all.css';
    @import '../../../form/bootstrap.css';
    @import '../../../form/alertify/alertify.min.css';
    @import '../../../form/alertify/themes/default.css';

 .bd-placeholder-img {
	font-size: 1.125rem;
	text-anchor: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}
@media (min-width: 768px) {
.bd-placeholder-img-lg {
	font-size: 3.5rem;
}
}

    @media only screen and (max-width: 1000px) {
        .col-8, .col-4 {
            width: 100%;
        }
    }

    row.kanaly {
        font-size: 0.8em;
    }

    .kanaly h5 {
        font-size: 1.2em !important;
    }

</style>
